 <template>
  <div class="bannerSwiper">
    <el-carousel trigger="click" :height="height">
      <el-carousel-item v-for="(item,i) in list" :key="i">
        <a target="_blank" class="link-class" v-if="item.link_src" :href="item.link_src">
          <el-image style="width: 100%; height: 100%" :src="item.url" :fit="fit||'contain'"/>
        </a>
        <el-image v-else style="width: 100%; height: 100%" :src="item.url" :fit="fit||'contain'"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import {bannerList } from "constants/publicData";
export default {
  name:"BannerSwiper",
  props:['height','swiperList','fit'],
  data:()=>({
  }),
  methods:{},
  computed:{
    list(){
      if(this.swiperList){
        let list = this.swiperList.map(item=>{
          return {
            url:item.img_url,
            link_src:item.link_src,
            title:item.title
          }
        })
        return list
      }
      return bannerList
    },
  },
  watch:{},

}
</script>
<style lang="scss">
// @import "constants/style/public";
.bannerSwiper{
  width: 100%;
  height: 100%;
  background-color: #fff;
  .el-carousel {
    height: 100%;
    .el-carousel__container{
      height: 100%;
    }
    .el-carousel__item{
      height: 100%;
    }
  }
  
  .link-class{
    width:100%;
    height:100%;
  }
}

</style>
 
 