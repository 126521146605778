<template>
  <div class="activity page-container">
    <BannerSwiper height="280px" />
    <ActivityFilter :initData="filterData" @change="getFilterData"/>
    <ActivityListWrapper :nums="20" pagination :filterData="filterData" style="margin-top:26px" />
  </div>
</template>
<script>//activity
import BannerSwiper from 'components/bannerSwiper.vue'
import ActivityFilter from "components/activity/activityFilter"
import ActivityListWrapper from "components/activity/activityListWrapper"
export default {
  name:"pageActivities",
  data:()=>({
    filterData:{
      search_content:""
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.search_content = this.$route.params.search_content||"" 
    this.filterData = filterData
  },
  methods:{
    getFilterData(data){
      this.filterData = data
    }
  },
  computed:{},
  watch:{},
  components: { BannerSwiper,ActivityListWrapper,ActivityFilter },
}
</script>
<style lang="scss">
// @import "constants/style/public";
</style>