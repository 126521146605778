<template>
  <div class="activitys-filter-wrapper">
      <div class="activitys-filter-card">
        <div class="activitys-filter-item top">
          <div class="activitys-filter-item-left">
            <div
              :class="'filter-job-cate ' + (curIndex === i ? 'active' : '')"
              v-for="(l, i) in selectKeys"
              :key="i"
              @click="toggleCate(i)"
              v-text="l.label"
            />
          </div>
          <div class="search-box">
            <el-input
              placeholder="请输入内容"
              class="search-input"
              prefix-icon="el-icon-search"
              @change="searched"
              v-model="search_content"
              size="small"
            >
            </el-input>
          </div>
        </div>

    
      </div>
    </div>
</template>
<script>
import {  activityCate } from "constants/publicData";
export default {
  name:"ActivityFilter",
  props:{
    initData:Object,
  },
  data(){
    return {
      curIndex: 0,
      search_content: "",
      selectItmes:{},
      back:{}
    } 
  },
  created(){
    if(this.initData){
      this.init(this.initData)
    }
  },
  methods: {
    init(initData){
      const {curIndex,search_content} = initData

      if(curIndex!==0&&curIndex!==undefined&&curIndex!==null&&curIndex!==""&&!isNaN(curIndex)){
        if(typeof curIndex=='number') this.curIndex = curIndex||0
        else console.error('initData.curIndex error'); 
      }

      activityCate.map((item) => {
        if (initData[item.value]) {
          this.selectItmes[item.value] = initData[item.value];
        }
      });

      if(search_content){
        this.search_content = search_content||""
      }
    },
    toggleCate(i) {
      this.curIndex = i;
      
      if(Object.keys(this.selectItmes).length){
        for(let key in this.selectItmes){
          this.selectItmes[key]=""
        }
      }
      
      if(activityCate[i]!==-1&&activityCate[i].value){
        this.selectItmes[activityCate[i].value]=true
      }else if(activityCate[i].value){
        this.selectItmes[activityCate[i].value]=""
      }
      this.callBack()
    },
    // handleCommand(command, name) {
    //   this[name] = command;
    //   this.callBack()
    // },
    searched(val) {
      this.search_content = val
      this.callBack()
    },
    // clear() {
    //   this.experiences = "";
    //   this.educations = "";
    //   this.salary_range = "";
    //   this.callBack()
    // },
    callBack(){
      let data = {
        curIndex :this.curIndex,
        // experiences:this.experiences,
        // educations:this.educations,
        // salary_range:this.salary_range,
        search_content:this.search_content,
        // industry:this.industry,
        ...this.selectItmes
      }
      console.log(data);
      this.$emit('change',data)
    }
  },
  computed: {
    selectKeys:()=>activityCate,
    // experienceList: () => experience,
    // salaryList: () => salary,
    // educationList: () => education,
  },
  watch: {},

}
</script>
<style lang="scss">
@import "constants/style/public";
.activitys-filter-card {
    background-color: $defaultBg;
    margin-top: 26px;
    padding: 15px 10px;
    box-shadow: 0 0 18px $shadowColor;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .activitys-filter-item {
    padding: 20px 13px 0;
    display: flex;
    justify-content: space-between;
    &.top {
      padding: 0;
    }
    .activitys-filter-item-left {
      display: flex;
      .dropdown-box {
        margin-right: 140px;
        .drop-title {
          color: $moreColor;
          margin-right: 20px;
        }
        .el-dropdown-link {
          cursor: pointer;
        }
      }
      .filter-job-cate {
        position: relative;
        cursor: pointer;
        margin: 0 13px;
        padding: 7px 23px;
        &.active {
          &::after{
            width: 100%;
            height: 2px;
            content: "";
            position: relative;
            top: 12px;
            background-color: var(--moreColor,#666);
            display: block;
          }
        }
      }
    }
    .search-input {
      .el-input__inner {
        border-radius: $borderRound;
        &:focus{
          border-color: $moreColor;
        }
        // background-color: $searchBg;
      }
    }
    .filter-clear {
      cursor: pointer;
      font-size: $medium;
      color: $moreColor;
    }
  }
</style>