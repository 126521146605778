<template>
  <div class="activity-list hasPagination">
    <div class="activity-list-wrapper">
      <div v-for="(l, i) in data" :key="i" class="item">
        <ActivityCard :data="l" :link=" l.career_fair_id === 1770 ? toSpecialCf : toActivityDetail+l.career_fair_id" />
      </div>
    </div>
    <div class="pagination" v-if="nums&&pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page"
        :total="total"
        :page-size="nums"
        :hide-on-single-page="!pagination||!total||total<nums"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ActivityCard from "components/activityCard";
import {toActivityDetail,toSpecialCf} from "constants/routerLink"
export default {
  name: "ActivityListWrapper",
  props: {
    nums: {
      type:Number,
      default:4
    },
    pagination: Boolean,
    filterData: {
      type:[Object],
      default(){
        return {}
      }
    },
  },
  data: () => ({
    page: 1,
    total: null,
    data: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    handleCurrentChange(page){
      this.page = page
      this.getData()
    },
    getData() {
      let params = {
          limit: this.nums,
          offset: (this.page-1)*this.nums,
          valley_id:294
        }
      let data = {...this.filterData}
      if (Object.values(this.filterData).length) {
        delete data.curIndex
        params = Object.assign(params,data);
      }
      // console.log(params);
      this.$axiosRequest({
        name: "getActivity",
        params: params
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200) {
            this.data = res.data.results;
            this.total = res.data.count
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    toActivityDetail:()=>toActivityDetail,
    toSpecialCf:()=>toSpecialCf
  },
  watch: {
    filterData(val,prev){
      if(val!==prev){
        this.page = 1
        this.getData();
      }
    }
  },
  components: {
    ActivityCard,
  },
};
</script>
<style lang="scss">
@import "constants/style/public";
.activity-list{
  .activity-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-right: 15px;
      margin-bottom: 15px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    } 
  }
  .pagination {
      margin: 30px auto 40px;
  }
}

</style>